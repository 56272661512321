.signin-form--container {
  padding: 0.7rem;
  text-align: center;
  position: relative;
  z-index: 5000;
}
.signin-form--container .icon-delete {
  position: absolute;
  top: 0.2rem;
  right: 0.5rem;
  z-index: 1000;
  font-size: clamp(3rem, 1.5vw, 3.5rem);
}

.signin-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: absolute;
  top: 16%;
  left: 50%;
  transform: translateX(-50%);
  padding: 1rem;
  border-radius: 1rem;
  background-color: #4844c1;
}
.signin-form > div {
  display: flex;
  flex-flow: row wrap;
  gap: 1.5rem;
  margin-top: 3rem;
}

.signin-form > div > input {
  font-family: inherit;
  padding: 1rem 1.5rem;
  border-radius: 0.9rem;
  font-size: clamp(2rem, 1.5vh, 2.5rem);
  border: none;
  outline: none;
}

.signin-form > div > input::placeholder,
.signin-form > div > input::-moz-placeholder,
.signin-form > div > input::-webkit-input-placeholder {
  font-size: clamp(1.7rem, 1.5vh, 2.5rem);
  font-family: inherit;
}
.signin-form > .sign-up {
  flex-direction: column;
}

.signin-form > div > p {
  font-family: inherit;
  font-size: clamp(2rem, 1.5vh, 2.5rem);

  color: rgb(249, 246, 239);
}

input[type="submit"] {
  font-family: inherit;
  font-size: clamp(1.7rem, 1.5vh, 2rem);
  margin-top: 2rem;
  padding: 1rem 1.5rem;
  border-radius: 0.9rem;
  width: 10rem;
  cursor: pointer;
  transition: 0.7s all;
  border: none;
  outline: none;
}

input[type="submit"]:hover {
  transform: scale(1.1);
}

input[type="submit"]:active {
  transform: scale(1.1);
}

.error-message {
  color: #fdf381;
  font-size: clamp(2rem, 1.5vh, 2.5rem);
}
