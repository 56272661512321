.error-page {
  padding: 2rem;
}
.error-page h2 {
  color: #4844c1;
}
.error-page p {
  color: #4844c1;
  font-size: clamp(1.5rem, 0.9vw, 2rem);
}
