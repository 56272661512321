.confirmation-container {
  height: 10rem;
  width: 25rem;
  padding: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%, -50%);
  background-color: #0e99d4;
  z-index: 2000;
}

.confirmation-container > p {
  font-family: inherit;
  font-size: clamp(2rem, 1.4vw, 2.5rem);
  color: rgba(255, 255, 255, 0.6);
}

.icon-delete {
  color: #fff;
  font-size: clamp(4rem, 2vw, 5rem);
  position: absolute;
  top: 1rem;
  right: 1rem;
}
