.contact {
  background-color: #4844c1;
  color: #f1f1f1;
  padding: 0.8rem;
  display: inline-block;
  position: absolute;
  top: 10rem;
  right: 2rem;
  z-index: 1000;
  border-radius: 2rem;
  animation-name: moveContactIntoView;
  animation-duration: 3s;
}

.contact .icon-delete {
  position: absolute;
  top: 0.2rem;
  right: 0.5rem;
  font-size: clamp(3rem, 1.5vw, 3.5rem);
}

.contact .contact-us-address {
  padding: 0.4rem;
}
.contact .contact-us-address > h4 {
  font-weight: 700;
  font-size: clamp(2.5rem, 0.7vw, 2.8rem);
}

.contact .contact-us-address > p {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  font-size: clamp(1.8rem, 0.7vw, 2.5rem);
}
.contact > form {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  text-align: center;
  padding: 0.7rem 1rem;

  width: clamp(30rem, 1.2vw, 35%);
  margin: 0 auto;
}

.contact > form > p {
  font-size: clamp(1.5rem, 1.3vw, 2rem);
}

.contact > form > textarea,
.contact > form > input {
  padding: 1.6rem 1.6rem;
  border-radius: 0.8rem;
  font-size: clamp(1.7rem, 1vh, 2rem);
  font-family: inherit;
  background-color: #fff;
  color: #000;
  resize: both;
  border: none;
  outline: none;
}
.contact > form > textarea::placeholder,
.contact > form > textarea::-moz-placeholder,
.contact > form > textarea::-webkit-input-placeholder,
.contact > form > input::placeholder,
.contact > form > input::-moz-placeholder,
.contact > form > input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
  font-size: clamp(1.5rem, 1.2vw, 2rem);
  font-family: inherit;
}

.contact > form > textarea {
  height: 20rem;
}

.contact > form button {
  margin-top: 1rem;
  padding: 0.6rem 0.8rem;
  background-color: #fff;
  font-size: clamp(1.7rem, 1vh, 2rem);
  color: #000;
  width: 15rem;
  transition: all 0.5s linear;
  border-radius: 1.5rem;
  font-family: inherit;
  transition: all 0.6s;
  border: none;
  outline: none;
}

.contact > form button:hover {
  transform: scale(1.2);
  font-weight: 700;
}

@media only screen and (max-width: 600px) {
  .contact {
    width: clamp(30rem, 1.4vw, 40%);
    top: 10rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .contact > form {
    width: clamp(25rem, 1.2vw, 35%);
  }
}
/*  */
@keyframes moveContactIntoView {
  from {
    transform: translateY(-10rem);
    width: 0;
  }

  to {
    transform: translateY(0);
    width: clamp(37rem, 1.4vw, 40%);
  }
}
