.dashboard {
  display: flex;
}

.dashboard > .left-side {
  flex-basis: clamp(15rem, 1vw, 25rem);
  display: flex;
  flex-direction: column;
  place-items: center;
  height: 100vh;
  background-color: #4844c1;
}

.dashboard > .left-side ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  font-size: 1.5vw;
  transition: all 0.6s linear;
}

.dashboard > .left-side ul li {
  padding: 0.6rem 0.7rem;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  color: #fff;
  font-size: clamp(2rem, 2vw, 3rem);
  transition: transform 0.7s linear;
}
.dashboard > .left-side ul li:hover {
  transform: scale(1.2);
  border-radius: 1.5rem;
  color: #fff;
}

.dashboard > .right-side {
  flex: 1;
  padding: 1rem;
}

.dashboard-home-content {
  display: flex;
  flex-flow: row wrap;
  place-items: center;
}

.home-upload-form {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  width: clamp(50rem, 2.5vw, 60rem);
  background-color: #4844c1;
  color: #f1f1f1;
  border-radius: 1.6rem;
  box-shadow: 1px 2px 3px #f1f1f1;
  overflow: hidden;
  text-align: center;
  transition: all 0.3s linear;
}

.home-form-upload input[type="submit"] {
  width: 15rem;
  font-size: clamp(2.2rem, 1.7vw, 2.5rem);
  color: #e0b321;
  transition: all 0.5s linear;
}

.home-form-upload input[type="submit"]:hover {
  background-color: #000;
}

input::file-selector-button {
  font-weight: bold;
  color: #000;
  border-radius: 0.8rem;
  border: none;
  outline: none;
}

.home-form-upload > input::placeholder,
.home-form-upload > textarea::placeholder {
  font-size: 1.5vw;
  padding: 1rem 1.3rem;
  color: #f1f1f1;
}

label {
  font-size: clamp(2.2rem, 1.7vw, 2.5rem) !important;
}

.error-message {
  color: rgb(211, 24, 24);
  margin-bottom: 0.5rem;
  text-align: center;
  font-weight: inherit;
  font-size: 1.5vw;
}

.success-message {
  color: rgb(46, 185, 46);
  margin-bottom: 0.5rem;
  text-align: center;
  font-size: 2.5vw;
  font-weight: inherit;
}

.category-view {
  overflow-x: auto;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 2rem;
}

.category-view > table {
  padding: 1rem;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1.7vw;
  width: 100%;
  background-color: #fff;
  transition: all 0.7s linear;
  position: relative;
  z-index: 600;
  overflow: scroll;
}

.category-view > table > thead > tr {
  font-size: 2vw;
  font-weight: 700;
  color: #fff;
  background-color: #0e99d4;
}

.category-view #menu-item td,
.category-view #menu-item > th {
  padding: 0.8rem;
  text-align: center;
}

.category-view #menu-item tr:nth-child(even),
.category-view tr:nth-child(even) {
  background-color: #cecece;
}

.category-view #menu-item tr:hover {
  background-color: rgba(14, 153, 212, 0.5);
}

.category-view #menu-item th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}

.category-view #menu-item td > .cell-content > input,
.category-view #menu-item td > .cell-content > textarea {
  padding: 0.6rem 0.7rem;
  outline: none;
}
.category-view #menu-item td > img {
  max-width: 100%;
  max-height: 5rem;
}

.category-view > table button {
  padding: 0.8rem 1.8rem;
  border-radius: 1rem;
  font-size: 1.7vw;
  border: none;
  outline: none;
}

.category-view > table .imgfood {
  max-width: 100%;
}

.next-previous-wrapper {
  margin-top: 1rem;
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;
}
.dropdown-container {
  display: flex;
  gap: 1.5rem;
}

.dropdown-container select {
  width: 6rem;
  font-size: clamp(2rem, 1.5vh, 2.5rem);
}
