.navigation-container {
  background-color: #4844c1;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.menu-list {
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
}
.menu-list a {
  color: #4844c1;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.7s linear;
  font-size: clamp(2rem, 1.5vw, 3.5rem);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.menu-list .active,
.menu-list a:hover {
  padding: 0.7rem 0.9rem;
  border-radius: 2rem;
}

.menu-list img {
  width: clamp(7rem, 5vw, 16rem);
  height: clamp(8rem, 9.5vh, 14rem);
}

.menu-list > .menu-list-item:first-child {
  margin-right: auto;
}

.hamburger-menu-container {
  display: none;
}

.hamburger-menu-container .hamburger-menu-icon {
  color: #4844c1;
  font-weight: 700;
  font-size: clamp(3.5rem, 2vw, 4rem);
}

.hamburger-menu-container .hamburger-logo-container {
  display: none;
}

.hamburger-menu-list a {
  font-size: clamp(2rem, 1.5vw, 3rem);
}

@media only screen and (max-width: 600px) {
  .main-nav {
    height: 17rem;
  }
  .menu-list {
    display: none;
  }
  .hamburger-menu-container {
    display: block;
    text-align: right;
  }

  .hamburger-menu-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
  }
  .hamburger-menu-icon {
    color: #fff !important;
    font-size: 4.5rem;
  }
  .hamburger-menu-list--color > a,
  .hamburger-menu-list--color > svg {
    color: #fff !important;
  }

  .hamburger-menu-list .menu-list-item:nth-child(2) {
    text-align: right;
    margin-right: 1rem;
  }

  .hamburger-menu-list .menu-list-item a,
  .hamburger-menu-list .menu-list-item svg {
    color: #4844c1;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.7s linear;
    font-size: clamp(2rem, 1.4vw, 2.5rem);
    transition: all 0.7s linear;
  }

  .hamburger-menu-list .menu-list-item .active,
  .hamburger-menu-list .menu-list-item a:hover,
  .hamburger-menu-list .menu-list-item svg:active,
  .hamburger-menu-list .menu-list-item svg:hover {
    transform: scale(1.2);
    padding: 0.7rem 0.9rem;
    border-radius: 2rem;
  }

  .hamburger-menu-container .hamburger-logo-container {
    display: block;
    position: absolute;
    top: 1rem;
  }
  .hamburger-menu-container .hamburger-logo-burger-container svg {
    margin-right: 1.5rem;
  }
  .hamburger-menu-container .hamburger-logo-burger-container img {
    width: clamp(4rem, 5vw, 16rem);
    margin-left: 0.7rem;
  }
}
