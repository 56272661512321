.photos {
  display: flex;
  flex-flow: row wrap;
}

@supports (display: grid) {
  .photos {
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 1.5rem;
  }
}

.photos img,
.photos video {
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  border-radius: 1rem;
  object-fit: cover;
  z-index: -1;
}
.photos video {
  position: relative;
  z-index: 1;
}
