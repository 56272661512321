@import url("https://fonts.googleapis.com/css2?family=Fanwood+Text:ital@0;1&family=Oswald:wght@200..700&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

h1 {
  font-size: clamp(3.5rem, 3vw, 4.5rem);
}

h2 {
  font-size: clamp(3rem, 2.5vw, 4rem);
}

h3 {
  font-size: clamp(2.5rem, 2vw, 3.5rem);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Oswald, serif;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Fanwood Text", sans-serif;
  font-size: 1.6vw;
  line-height: 1.7;
  background-color: #fff;
  color: #4844c1;
}
